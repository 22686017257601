// App.js
import { Routes, Route } from "react-router-dom";
import HomePage from "./modules/pages/home";
import ServicesPage from "./modules/pages/services";
import AboutPage from "./modules/pages/about";
import ContactForm from "./modules/pages/contact";
import PortfolioPage from "./modules/pages/portfolio";
import ElementsPage from "./modules/pages/portfolioPage/elements";
import GoldenPage from "./modules/pages/portfolioPage/golden";
import BridePage from "./modules/pages/portfolioPage/bride";
import SundancePage from "./modules/pages/portfolioPage/sundance";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/portfolio/elements" element={<ElementsPage />} />
        <Route path="/portfolio/golden" element={<GoldenPage />} />
        <Route path="/portfolio/bride" element={<BridePage />} />
        <Route path="/portfolio/sundance" element={<SundancePage />} />
      </Routes>
    </>
  );
};

export default App;
