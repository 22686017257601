import React from "react";
import servicesHero from "../../img/servicesHero.jpg";
import Nav from "../components/nav";
import aliOnFloor from "../../img/aliOnFloor.jpg";
import aliStanding from "../../img/aliStanding.jpg";
import aliFierce from "../../img/aliFierce.jpg";
import { useNavigate } from "react-router-dom";

export default function ServicesPage() {
  const navigate = useNavigate();

  return (
    <>
      <header style={{ paddingLeft: 0 }}>
        <div
          className="p-5 text-center bg-image"
          style={{
            backgroundImage: `url(${servicesHero})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
            height: "100vh",
          }}
        >
          <div
            className="mask"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              position: "absolute",
              height: "100vh",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Nav />
            </div>
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="text-white">
                <h1
                  style={{ textTransform: "uppercase", letterSpacing: "5px" }}
                >
                  services
                </h1>
              </div>
            </div>
          </div>
        </div>
      </header>
      <body>
        <div style={{ textAlign: "center" }}>
          <h1
            style={{
              textTransform: "uppercase",
              paddingTop: "40px",
              letterSpacing: "1px",
            }}
          >
            Content <i>planning</i>
          </h1>
          <p
            style={{
              display: "inline-block",
              textAlign: "left",
              padding: "20px",
            }}
          >
            Our Content Planning service is the perfect option for the business
            owner who isn’t quite ready to invest in full-scale social media
            management, but still wants a customized plan. We handle the
            planning, you handle the posting. A content calendar will be
            delivered every 2 weeks that includes copywriting and hashtags. You
            will also be provided with a strategy document, to ensure that each
            post has a purpose.
          </p>

          <img
            src={aliOnFloor}
            alt="ali sevy sitting on floor"
            style={{ width: "100vw" }}
          ></img>

          <h1
            style={{
              textTransform: "uppercase",
              paddingTop: "40px",
              letterSpacing: "1px",
            }}
          >
            social media <i>management</i>
          </h1>
          <p
            style={{
              display: "inline-block",
              textAlign: "left",
              padding: "20px",
            }}
          >
            With our full scale social media management plans, you'll have the
            freedom to focus on your core business while we handle your online
            presence. These plans vary, but can include a social media audit,
            content, hashtag, & keyword strategy, content creation & graphic
            design, engagement & growth tactics, scheduling, publishing,
            comprehensive reports, and more.{" "}
          </p>
          <img
            src={aliStanding}
            alt="ali sevy standing with laptop"
            style={{ width: "100vw" }}
          ></img>
          <h1
            style={{
              textTransform: "uppercase",
              paddingTop: "40px",
              letterSpacing: "1px",
            }}
          >
            social media<i> strategy</i>
          </h1>
          <p
            style={{
              display: "inline-block",
              textAlign: "left",
              padding: "20px",
            }}
          >
            We believe that a solid social media account thrives on a foundation
            of strategic planning. This is why our first step is always to gain
            a clear understanding of our client’s business structure, brand
            voice, target audience, and competitors. We work diligently to align
            your messaging with your brand identity, ensuring it resonates with
            your audience. We also conduct thorough competitor analysis to
            identify opportunities and refine your approach. Plus, we don't just
            stop at planning; we also develop compelling content ideas to engage
            and captivate your followers.
          </p>
          <h1 style={{ textTransform: "uppercase" }}>Add ons</h1>
          <div
            style={{
              textAlign: "left",
              padding: "20px",
              paddingLeft: "50px",
            }}
          >
            <h3 style={{ textTransform: "uppercase", fontSize: "16px" }}>
              blog writing
            </h3>
            <h3 style={{ textTransform: "uppercase", fontSize: "16px" }}>
              on site content creation
            </h3>
            <h3 style={{ textTransform: "uppercase", fontSize: "16px" }}>
              social media audit
            </h3>
            <h3 style={{ textTransform: "uppercase", fontSize: "16px" }}>
              additional platforms
            </h3>
            <h3
              style={{
                textTransform: "uppercase",
                fontSize: "16px",
                color: "#A020F0",
              }}
            >
              <br></br>*new*
            </h3>
            <h3 style={{ textTransform: "uppercase", fontSize: "16px" }}>
              website refresh
            </h3>
            <h3 style={{ textTransform: "uppercase", fontSize: "16px" }}>
              landing page creation
            </h3>
            <h3 style={{ textTransform: "uppercase", fontSize: "16px" }}>
              custom website solutions
            </h3>
          </div>
          <img
            src={aliFierce}
            alt="ali sevy on stool"
            style={{ width: "100vw", paddingBottom: "40px" }}
          ></img>
          <div style={{ paddingBottom: "40px" }}>
            <button
              style={{
                textTransform: "uppercase",
                backgroundColor: "black",
                color: "white",
                padding: "10px",
                fontSize: "12px",
                letterSpacing: "3px",
                fontFamily: "Cormorant Garamond",
              }}
              onClick={() => navigate("/contact")}
            >
              Let's work together
            </button>
          </div>
        </div>
      </body>
    </>
  );
}
