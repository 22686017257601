import React from "react";
import portfolioHero from "../../img/portfolioHero.jpg";
import Nav from "../components/nav";
import elementsRemedy from "../../img/elementsRemedy1.jpg";
import goldenMae from "../../img/goldeMae1.jpg";
import slcBridal from "../../img/slcBridal.jpg";
import sundanceRoofs from "../../img/sundanceRoofs.jpg";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export default function PortfolioPage() {
  const navigate = useNavigate();
  return (
    <>
      <header style={{ paddingLeft: 0 }}>
        <div
          className="p-5 text-center bg-image"
          style={{
            backgroundImage: `url(${portfolioHero})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
            height: "100vh",
          }}
        >
          <div
            className="mask"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              position: "absolute",
              height: "100vh",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Nav />
            </div>
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="text-white">
                <h1
                  style={{ textTransform: "uppercase", letterSpacing: "5px" }}
                >
                  Portfolio
                </h1>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div
        style={{
          display: "flex",
          paddingTop: "40px",
          paddingBottom: "3vw",
        }}
      >
        <div
          style={{
            flex: 1,
            paddingLeft: "3vw",
          }}
        >
          <Link to="/portfolio/elements">
            <div
              style={{
                width: "45vw",
                height: "45vw",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <img
                src={elementsRemedy}
                alt="Image description"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />

              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "black",
                  opacity: "0.5",
                }}
              ></div>

              <p
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  fontSize: "20px",
                  textAlign: "center",
                  fontFamily: "Cormorant Garamond",
                  textTransform: "uppercase",
                  letterSpacing: "3px",
                }}
              >
                Elements <i>by remedy</i>
              </p>
            </div>
          </Link>
        </div>
        <div style={{ flex: 1 }}>
          <Link to="/portfolio/golden">
            <div
              style={{
                width: "45vw",
                height: "45vw",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <img
                src={goldenMae}
                alt="Image description"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />

              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "black",
                  opacity: "0.5",
                }}
              ></div>

              <p
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  fontSize: "20px",
                  textAlign: "center",
                  fontFamily: "Cormorant Garamond",
                  textTransform: "uppercase",
                  letterSpacing: "3px",
                }}
              >
                Golden <i>mae</i>
              </p>
            </div>
          </Link>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          paddingBottom: "30px",
        }}
      >
        <div
          style={{
            flex: 1,
            paddingLeft: "3vw",
          }}
        >
          <Link to="/portfolio/bride">
            <div
              style={{
                width: "45vw",
                height: "45vw",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <img
                src={slcBridal}
                alt="Image description"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />

              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "black",
                  opacity: "0.5",
                }}
              ></div>

              <p
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  fontSize: "20px",
                  textAlign: "center",
                  fontFamily: "Cormorant Garamond",
                  textTransform: "uppercase",
                  letterSpacing: "3px",
                }}
              >
                Salt lake <i>bride</i>
              </p>
            </div>
          </Link>
        </div>

        <div style={{ flex: 1 }}>
          <Link to="/portfolio/sundance">
            <div
              style={{
                width: "45vw",
                height: "45vw",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <img
                src={sundanceRoofs}
                alt="Image description"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />

              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "black",
                  opacity: "0.5",
                }}
              ></div>

              <p
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  fontSize: "20px",
                  textAlign: "center",
                  fontFamily: "Cormorant Garamond",
                  textTransform: "uppercase",
                  letterSpacing: "3px",
                }}
              >
                Sundance louvered <i>roofs</i>
              </p>
            </div>
          </Link>
        </div>
      </div>
      <div style={{ paddingBottom: "50px", textAlign: "center" }}>
        <button
          style={{
            textTransform: "uppercase",
            backgroundColor: "black",
            color: "white",
            padding: "10px",
            fontSize: "12px",
            letterSpacing: "3px",
            fontFamily: "Cormorant Garamond",
          }}
          onClick={() => navigate("/contact")}
        >
          Let's work together
        </button>
      </div>
    </>
  );
}
