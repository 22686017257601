import React from "react";
import Nav from "../components/nav";
import aliOnStool from "../../img/aliOnStool.jpg";
import aliWithLaptop from "../../img/aliWithLaptop.jpg";
import { useNavigate } from "react-router-dom";

export default function AboutPage() {
  const navigate = useNavigate();
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            backgroundColor: "black",
          }}
        >
          <Nav />
        </div>
        <h1 style={{ textTransform: "uppercase", paddingTop: "50px" }}>
          Hi, I'm <i> Ali Sevy</i>
        </h1>
        <p style={{ fontSize: "12px" }}>
          utah based, serving clients worldwide
        </p>
        <div style={{ display: "flex" }}>
          <img
            src={aliWithLaptop}
            alt="ali sevy with computer"
            style={{
              display: "inline-block",
              width: "50%",
              paddingLeft: "20px",
              paddingTop: "20px",
              paddingRight: "10px",
              paddingBottom: "20px",
            }}
          ></img>
          <img
            src={aliOnStool}
            alt="ali sevy on stool"
            style={{
              display: "inline-block",
              width: "50%",
              paddingLeft: "10px",
              paddingTop: "20px",
              paddingRight: "20px",
              paddingBottom: "20px",
            }}
          ></img>
        </div>
        <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
          <p style={{ display: "inline-block", textAlign: "left" }}>
            I have a bachelor of science marketing degree, with an emphasis in
            digital marketing. After working in the industry both agency side
            and in house, I’ve developed a passion for helping businesses
            improve and grow their online presence through social media.
          </p>
          <p style={{ display: "inline-block", textAlign: "left" }}>
            At Willow Marketing, our mission is simple - we’re here to help you
            build the social media presence of your dreams. Our approach
            combines creativity, data-driven insights, and the latest industry
            trends to ensure your social media accounts not only thrive but also
            drive meaningful engagement and tangible results.
          </p>
        </div>
        <div style={{ paddingBottom: "50px", paddingTop: "20px" }}>
          <button
            style={{
              textTransform: "uppercase",
              backgroundColor: "black",
              color: "white",
              padding: "10px",
              fontSize: "12px",
              letterSpacing: "3px",
              fontFamily: "Cormorant Garamond",
            }}
            onClick={() => navigate("/contact")}
          >
            Let's work together
          </button>
        </div>
      </div>
    </>
  );
}
