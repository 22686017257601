import React, { useState } from "react";
import Nav from "../components/nav";
import background from "../../img/background.jpg";
import { useNavigate } from "react-router-dom";

const FORM_ENDPOINT =
  "https://public.herotofu.com/v1/ab4f5f70-5667-11ee-b04f-17ba4776c03d"; // TODO - update to the correct endpoint

const ContactForm = () => {
  const navigate = useNavigate();

  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();

    const inputs = e.target.elements;
    const data = {};

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        data[inputs[i].name] = inputs[i].value;
      }
    }

    fetch(FORM_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Form response was not ok");
        }

        setSubmitted(true);
      })
      .catch((err) => {
        // Submit the form manually
        e.target.submit();
      });
  };

  if (submitted) {
    return (
      <>
        <div
          style={{
            backgroundImage: `url(${background})`,
            height: "100vh",
            width: "100vw",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              position: "relative",
            }}
          >
            <Nav />
          </div>
          <div
            style={{
              backgroundColor: "black",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: "45px",
              borderRadius: "25px",
              textAlign: "center",
            }}
          >
            <h1 style={{ color: "white", paddingBottom: "10px" }}>
              Thank you!
            </h1>
            <button className="btn btn-light" onClick={() => navigate("/")}>
              Go Home
            </button>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${background})`,
          height: "100vh",
          width: "100vw",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            position: "relative",
          }}
        >
          <Nav />
        </div>
        <div
          style={{
            backgroundColor: "black",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "45px",
            textTransform: "uppercase",
          }}
        >
          <form
            action={FORM_ENDPOINT}
            onSubmit={handleSubmit}
            method="POST"
            style={{
              textAlign: "center",
              fontFamily: "Cormorant Garamond",
            }}
          >
            <h1 style={{ color: "white" }}>Contact</h1>
            <div className="pt-0 mb-3">
              <input
                type="text"
                placeholder="Name"
                name="name"
                className="focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white border-0 rounded shadow outline-none"
                required
              />
            </div>
            <div className="pt-0 mb-3">
              <input
                type="email"
                placeholder="Email"
                name="email"
                className="focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white border-0 rounded shadow outline-none"
                required
              />
            </div>

            <div className="pt-0 mb-3">
              <input
                type="text"
                placeholder="Instagram handle"
                name="instagram"
                className="focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white border-0 rounded shadow outline-none"
                required
              />
            </div>

            <div className="pt-0 mb-3">
              <textarea
                placeholder="Your message"
                name="message"
                className="focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white border-0 rounded shadow outline-none"
                required
              />
            </div>
            <div
              style={{
                textIndent: "-99999px",
                whiteSpace: "noWrap",
                overflow: "hidden",
                position: "absolute",
              }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="_gotcha"
                tabIndex="-1"
                autoComplete="off"
              />
            </div>
            <div className="pt-0 mb-3">
              <button
                className="btn btn-light"
                type="submit"
                style={{
                  textTransform: "uppercase",
                  fontFamily: "Cormorant Garamond",
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
