import React from "react";
import Nav from "../../components/nav";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import golden1 from "../../../img/golden1.jpg";
import golden2 from "../../../img/golden2.jpg";

export default function GoldenPage() {
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          display: "flex",
          backgroundColor: "black",
        }}
      >
        <Link to="/portfolio" style={{ textDecoration: "none" }}>
          <h1
            style={{
              fontSize: "20px",
              paddingLeft: "15px",
              paddingTop: "12px",
              color: "white",
            }}
          >
            back to portfolio
          </h1>
        </Link>
        <div style={{ marginLeft: "auto" }}>
          <Nav />
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <h1 style={{ paddingTop: "40px", textTransform: "uppercase" }}>
          Godlen <i>mae</i>
        </h1>
        <div style={{ padding: "20px" }}>
          <p style={{ display: "inline-block", textAlign: "left" }}>
            Golden Mae is a jewelry company. Below see samples of an Instagram
            feed and an Instagram post & caption all created by Willow
            Marketing.
          </p>
        </div>
        <h1
          style={{
            textTransform: "uppercase",
            padding: "20px",
            paddingTop: "0px",
          }}
        >
          Instagram
        </h1>
        <img
          src={golden1}
          alt="social media image"
          className="portfolio-page"
        ></img>
        <br></br>
        <img
          src={golden2}
          alt="social media image"
          className="portfolio-page"
        ></img>
      </div>
      <div
        style={{
          paddingBottom: "50px",
          textAlign: "center",
          paddingTop: "30px",
        }}
      >
        <button
          style={{
            textTransform: "uppercase",
            backgroundColor: "black",
            color: "white",
            padding: "10px",
            fontSize: "12px",
            letterSpacing: "3px",
            fontFamily: "Cormorant Garamond",
          }}
          onClick={() => navigate("/contact")}
        >
          Let's work together
        </button>
      </div>
    </>
  );
}
