import React from "react";
import background from "../../img/background.jpg";
import Nav from "../components/nav";
import aliStool from "../../img/aliStool.jpg";
import logo from "../../img/willowLogo.png";
import aliCoding from "../../img/aliCoding.jpg";
import { useNavigate } from "react-router-dom";

export default function HomePage() {
  const navigate = useNavigate();
  return (
    <>
      <header style={{ paddingLeft: 0 }}>
        <div
          className="p-5 text-center bg-image"
          style={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
            height: "100vh",
          }}
        >
          <div
            className="mask"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              position: "absolute",
              height: "100vh",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                paddingTop: "10px",
                justifyContent: "space-between",
                marginLeft: "10px",
              }}
            >
              {/* <div
                style={{ flexGrow: 1, width: 0, height: 0, opacity: 0 }}
              ></div> */}

              <img src={logo} className="logo"></img>
              {/* <div
                style={{ flexGrow: 1, width: 0, height: 0, opacity: 0 }}
              ></div> */}
              <Nav />
            </div>

            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="text-white">
                <h1 style={{ textTransform: "uppercase" }}>Willow Marketing</h1>
                <p style={{ fontSize: "12px" }}>
                  helping clients build the socials of their dreams
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>
      <body>
        <div style={{ textAlign: "center" }}>
          <h1
            style={{
              textTransform: "uppercase",
              paddingTop: "50px",
              paddingBottom: "20px",
            }}
          >
            {" "}
            Stand out <br></br>
            <i>on social</i>
          </h1>
          <div
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingBottom: "20px",
            }}
          >
            <p style={{ display: "inline-block", textAlign: "left" }}>
              At Willow Marketing, we specialize in crafting tailored,
              results-driven social media strategies that will elevate your
              online presence and drive growth. We offer a variety of services
              and custom packages that will help your business stand out on
              social media. Whether you’re wanting a stand alone audit or full
              scale management, we have something for everyone!{" "}
            </p>
          </div>
          <button
            style={{
              textTransform: "uppercase",
              backgroundColor: "black",
              color: "white",
              padding: "10px",
              fontSize: "12px",
              letterSpacing: "3px",
              fontFamily: "Cormorant Garamond",
            }}
            onClick={() => navigate("/services")}
          >
            explore our services
          </button>
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <img
              src={aliStool}
              alt="ali sevy"
              style={{
                width: "100vw",
              }}
            ></img>
          </div>
          <h1
            style={{
              textTransform: "uppercase",
              paddingTop: "20px",
              color: "#A020F0",
            }}
          >
            **new**
          </h1>
          <h1 style={{ textTransform: "uppercase", paddingBottom: "20px" }}>
            Website Updates & Custom Web Solutions
          </h1>
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <p style={{ display: "inline-block", textAlign: "left" }}>
              We're thrilled to announce we've expanded our offerings! In
              addition to our top-notch social media marketing services, we can
              now help you elevate your online presence with website updates and
              custom web solutions.
            </p>
          </div>
          <button
            style={{
              textTransform: "uppercase",
              backgroundColor: "black",
              color: "white",
              padding: "10px",
              fontSize: "12px",
              letterSpacing: "3px",
              fontFamily: "Cormorant Garamond",
            }}
            onClick={() => navigate("/services")}
          >
            learn more
          </button>
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <img
              src={aliCoding}
              alt="computer desk"
              style={{
                width: "100vw",
              }}
            ></img>
          </div>
          <h1
            style={{
              textTransform: "uppercase",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            Get to <br></br> know <i> us</i>
          </h1>
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <p style={{ display: "inline-block", textAlign: "left" }}>
              We work with passionate brands who are ready to take their
              business to the next level through aesthetic and meaningful
              content. With experience both agency side and in house, we know
              what it takes to elevate your brand’s presence on social media and
              break through the noise. Think you’re a good fit? Reach out to us
              today and let’s get started!
            </p>
          </div>
          <div style={{ paddingBottom: "50px", paddingTop: "20px" }}>
            <button
              style={{
                textTransform: "uppercase",
                backgroundColor: "black",
                color: "white",
                padding: "10px",
                fontSize: "12px",
                letterSpacing: "3px",
                fontFamily: "Cormorant Garamond",
              }}
              onClick={() => navigate("/contact")}
            >
              Let's work together
            </button>
          </div>
        </div>
      </body>
    </>
  );
}
